import { getEnvVariable } from "../environments/env";
const updateStatusUri = `${getEnvVariable().base_url}/SSO`;
export const urls ={
   applications:{
    getRegisterApplicaiton:(payerId:any,environment:string,pageNumber:number,pageSize:number)=>`/SSO/GetRegisteredApps?PayerID=${payerId}&environment=${environment}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=createdAt&sortOrder=desc`,
    getApplicationById:(id:number)=>`/SSO/GetRegisteredApps/${id}`,
    updateApplicationStatus : "https://aaneelconnect-api-sandbox.azurewebsites.net/SSO",
   
   getPayerMembers: (MemberID:any, FirstName:any, LastName:any,DOB:any,Email:any) => {
      let queryParams = [];

      // Add query parameters only if they are provided
      if (MemberID) queryParams.push(`MemberID=${MemberID}`);
      if (FirstName) queryParams.push(`FirstName=${FirstName}`);
      if (LastName) queryParams.push(`LastName=${LastName}`);
      if (DOB) queryParams.push(`DOB=${DOB}`);
      if (Email) queryParams.push(`Email=${Email}`);
      // Add pagination and sorting parameters (which are always required)
      queryParams.push(`PageNumber=1`);
      queryParams.push(`PageSize=10`);
      queryParams.push(`SortBy=FirstName`);
      queryParams.push(`SortOrder=asc`);

      // Join all parameters with '&' and return the full URL
      return `/PortalMember/members?${queryParams.join('&')}`;
    },
    getPortalUsersList: (FirstName:string, LastName:string, Username:string, Email:string, IsActive:any,PageSize:number ) => {
      let queryParams = [];
    
      // Add query parameters only if they are provided
      if (FirstName) queryParams.push(`FirstName=${FirstName}`);
      if (LastName) queryParams.push(`LastName=${LastName}`);
      if (Username) queryParams.push(`Username=${Username}`);
      if (Email) queryParams.push(`Email=${Email}`);
      if (IsActive) queryParams.push(`IsActive=${IsActive}`);
      if(PageSize) queryParams.push(`PageSize=${PageSize}`)
      // Return the full URL
      return `/PortalUser/users?${queryParams.join('&')}`;
    },
    
    getUserRoles:"/PortalRoles/roles",
    getUserByID:(id:any)=>`/PortalUser/user/${id}`,
    updateUser:"/PortalUser/user",
    createUser:"/PortalUser/user",
    getUserByEmail: (email: string) => `/PortalUser/user-by-email?email=${email}`,
    getMemberBySSOID:(ssoID:string)=>`/PortalUser/user-by-ssoid/${ssoID}`
    
   }
}