import { urls } from "../Urls";
import { Http } from "../Http";
import { getEnvVariable } from "../../environments/env";

export class ApplicationApi {
  static getregisterdApps(payerID:any,environment:string,pageNumber:number,pageSize:number){
    const url = urls.applications.getRegisterApplicaiton(payerID,environment,pageNumber,pageSize)
    return Http.get(url)
  }
 static getApplictionById(id:number){
  const url = urls.applications.getApplicationById(id)
  return Http.get(url)
 }
 static getPayerMemeberList(MemberID?:any, FirstName?:string, LastName?:string,DOB?:any,Email?:string){
  const url = urls.applications.getPayerMembers(MemberID,FirstName,LastName,DOB,Email)
 return Http.get(url)
}
static getPortalUserList(FirstName?:any,LastName?:any,UserName?:any,Email?:any,IsActive?:any,PageSize?:any){
  const url = urls.applications.getPortalUsersList(FirstName,LastName,UserName,Email,IsActive,PageSize)
  return Http.get(url)
}
static getUserRole(){
  return Http.get(urls.applications.getUserRoles)
}
static getUserByID(id:any){
  const url = urls.applications.getUserByID(id)
  return Http.get(url)
}

static getUserBySSOID(id:string){
  const url = urls.applications.getMemberBySSOID(id)
  return Http.get(url)
}

static getUserByEmail(email:any){
  const url = urls.applications.getUserByEmail(email)
  return Http.checkEmailExistence(url)
}
static updateApplicationStatus(data:any){
  const updateStatusUri = `${getEnvVariable().base_url}/SSO`;
  return Http.put(updateStatusUri,data)
}

static updateUserDetails=(data:Object)=>{
 return Http.put(urls.applications.updateUser,data)
}

static createNewUser(data:object){
 return Http.post(urls.applications.createUser,data)
}

}